<template lang="">
  <p class="has-text-centered mt-5">
    <span class="is-size-1-desktop is-size-2-mobile ma-full-gradient-text has-text-weight-bold">The Premed Presidents</span>
    <br>
    <span class="is-size-4-desktop is-size-4-mobile ma-full-gradient-text has-text-weight-semibold">Meet some of the bold & brilliant MedAngle Premed Presidents revolutionizing premed education.</span>

  </p>


  <div class="hq-motm-container">
    <Vue3Marquee :gradient="false" :pauseOnHover="true" :duration=36>
      <div v-for="profile in profiles" :key="profile.name" class="ppp-careers-profile" :style="{ backgroundImage: `url('${profile.image}')` }">
        <div class="ppp-content">
          <p class="is-size-6-desktop is-uppercase has-text-weight-bold has-text-white">{{ profile.school }}</p>
          <p class="is-size-3-desktop is-size-4-tablet is-size-4-mobile has-text-weight-bold has-text-white">{{ profile.name }}</p>
          <p class="is-size-7 is-uppercase has-text-weight-bold has-text-white">Season 1</p>
        </div>
      </div>
    </Vue3Marquee>
  </div>

<p class="has-text-centered mt-5">
    <span class="is-size-1-desktop is-size-2-mobile ma-full-gradient-text has-text-weight-bold">Want to be a Premed President?</span>
    <br>
    <span class="is-size-4-desktop is-size-4-mobile ma-full-gradient-text has-text-weight-semibold">Think you have what it takes? We select the best, most bold, ethical and genuine future trailblazers like you. <br> Together, we'll continue to revolutionize the future of premed education.</span>
</p>
<br>
<div class="has-text-centered">
  <a href="https://premed.careers/join"><button class="button is-large is-link has-text-weight-bold">Apply now -></button></a>
</div>
<br><br>
</template>


<script>
import { Vue3Marquee } from 'vue3-marquee';

function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default {
  components: {
    Vue3Marquee
  },
  data() {
    return {
      profiles: [
        { name: 'Mariam Meghani', school: 'Karachi Grammar School', image: 'https://res.cloudinary.com/medangle/image/upload/v1695555991/mariam-meghani.jpg' },
        { name: 'Ahsan Rizwan', school: 'Cedar College', image: 'https://res.cloudinary.com/medangle/image/upload/v1695556467/ahsan-rizwan.jpg' },
        { name: 'Zainab Akhlaq', school: 'Karachi Medical & Dental College', image: 'https://scdn.medangle.com/premed/ppp/zainab-akhlaq.jpg' },
        { name: 'Alishba Arfan', school: 'Dow Medical College', image: 'https://scdn.medangle.com/premed/ppp/alishba-arfan.jpg' },
        { name: 'Tehreem Rao', school: 'Dow Medical College', image: 'https://scdn.medangle.com/premed/ppp/tehreem-rao.jpg' },
        { name: 'Owais Amin', school: 'Cedar College', image: 'https://scdn.medangle.com/premed/ppp/owais-amin.jpg' },
        { name: 'Muhammad Zeeshan', school: 'Khyber Medical College', image: 'https://scdn.medangle.com/premed/ppp/muhammad-zeeshan.jpg' },
        { name: 'Najia Ali', school: 'Khyber Medical College', image: 'https://scdn.medangle.com/premed/ppp/najia-ali.jpg' },
        { name: 'Shanai Abbas', school: 'Multan Public College', image: 'https://scdn.medangle.com/premed/ppp/shanai-abbas.jpg' },
        { name: 'Rameen Malik', school: 'Jinnah College for Women', image: 'https://scdn.medangle.com/premed/ppp/rameen-malik.jpg' },
        { name: 'Aneeqa Mehmood', school: 'Cedar College', image: 'https://scdn.medangle.com/premed/ppp/aneeqa-mehmood.jpg' },
      ]
    };
  },
  created() {
    this.profiles = shuffle(this.profiles);
  }
}

</script>


<style lang="scss" scoped>
$medangle-red-cmyk: #ea5757;
$medangle-blue-cmyk: #6b93cc;
$medangle-green-cmyk: #73c382;


.hq-motm-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ppp-careers-profile {
  background-color: #F5F5F7;
  width: 400px;
  height: 500px;
  background-position: center center;
  background-size: cover;
  border-radius: 15px;
  color: #000;
  padding: 30px;
  position: relative;
  margin: 2rem 1rem;
}

.ppp-careers-profile::before {
  z-index: 0;
  content: "";
  /* necessary for the pseudo-element to be generated */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 25%;
  /* cover top 20% of the parent div */
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  /* translucent dark to transparent gradient */
  border-radius: 15px 15px 0 0;
  /* match the parent's border-radius on the top corners */
  pointer-events: none;
  /* ensure that it doesn't interfere with any interactions */
}

/* Change the text color at the top of the div to white */
.ppp-careers-profile>.top-text {
  color: #fff;
}

.ppp-careers-profile>.ppp-content {
  position: relative;
  /* required for z-index to take effect */
  z-index: 1;
  /* set it higher than the ::before pseudo-element */
}
</style>